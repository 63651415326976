const defaultState = {
    showMobileMenu: false,
    links:[
        {text: "Home", to: "", hasMegaMenu: false, megaMenuLoad: null},
        {text: "Services", to: "services", hasMegaMenu: true, megaMenuLoad: 'services'},
        {text: "Education", to: "education", hasMegaMenu: true, megaMenuLoad: 'education'},
        {text: "Aerial Imagery Archive", to: "aerial", hasMegaMenu: true, megaMenuLoad: 'aerial'},
        {text: "Research", to: "research", hasMegaMenu: true, megaMenuLoad: 'research'},
        {text: "About Us", to: "about", hasMegaMenu: true, megaMenuLoad: 'about'},
    ] 
}

export default (state = defaultState, action) => {
    if (action.type === "NAVBAR_TOGGLE_MOBILE_MENU") {
        return {
            ...state,
            showMobileMenu: !state.showMobileMenu
        }
    }
    else {
        return state;
    }
};

