
const defaultState = {
    baseUrls: {
        route: "/",
        public: "/public/"
    }
}

export default (state = defaultState, action) => {
    switch(action.type){
        case "GLOBALS_SET_BASE_URL":
            return {
                ...state,
                baseUrls: action.baseUrls
            };
        default:
            return state;
    }
};

