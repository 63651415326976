const defaultState =  {
    classToRegisterFor: -1
};
export default (state = defaultState, action) => {
    switch (action.type){
        case 'SET_CLASS_TO_REGISTER_FOR':
            return {
                ...state,
                classToRegisterFor: action.classToRegisterFor
            };
        default:
            return state;
    }
};