/********************
 *      Fonts       *
 ********************/
require("./fonts/PER_____.woff");
require("./fonts/PER_____.ttf");
require("./fonts/PERI____.woff");
require("./fonts/PERI____.ttf");
require("./fonts/PERB____.woff");
require("./fonts/PERB____.ttf");
require("./fonts/PERBI___.woff");
require("./fonts/PERBI___.ttf");
/********************
 *   Images  *
 ********************/

require("./images/masthead-helmet-white.svg");
require("./images/masthead-helmet-green.svg");
require("./images/msu-wordmark-green.svg");
require("./images/caro1.jpg");
require("./images/caro2.jpg");
require("./images/RSGIS_white.png");
require("./images/featurednews1.jpg");
require("./images/featurednews2.jpg");
require("./images/featurednews3.jpg");
require("./images/uas1.png");
require("./images/uas2.png");
require("./images/uas3.png");
require("./images/uas4.png");
require("./images/AeroVironment_Quantix.png");
require("./images/DJI_Matrice_210_v2.png");
require("./images/DJI_Matrice_600_Pro.png");
require("./images/DJI_Mavic2_Pro.png");
require("./images/Headwall-Co-Aligned-Hyperspectral-LiDAR.png");
require("./images/MicaSense-RedEdge-M.png");
require("./images/Micasense-RedEdge-MX.png");
require("./images/Zenmuse-XT-Thermal-Camera.png");
require("./images/uas5.png");
require("./images/uas6.jpg");
require("./images/uas7.jpg");
require("./images/uas8.jpg");
require("./images/uas9.jpg");
require("./images/uas10.jpg");
require("./images/uas11.jpg");
require("./images/uas12.jpg");
require("./images/uas13.jpg");
require("./images/uas14.png");
require("./images/uas15.png");
require("./images/uas16.png");
require("./images/uas17.png");
require("./images/uas18.png");
require("./images/uas19.png");
require("./images/uas20.png");
require("./images/uas21.jpg");
require("./images/uas22.jpg");
require("./images/uas23.jpg");
require("./images/uas24.jpg");
require("./images/uas25.jpg");
require("./images/uas26.jpg");
require("./images/uas27.jpg");
require("./images/uas28.png"); 
require("./images/uas29.png");
require("./images/uas30.jpg");
require("./images/uas31.jpg");
require("./images/uas32.png");
require("./images/uas33.png");
require("./images/uas34.png");
require("./images/uas35.png");
require("./images/uas36.png");
require("./images/uas37.png");
require("./images/uas38.png");
require("./images/uas_3D_point_cloud.png");
require("./images/uas_thermal.png");
require("./images/NSF_logo.png");
require("./images/ProjectGreen_logo.png");
require("./images/ABT_logo.png");
require("./images/Beaumont_Snow.jpg");
require("./images/bob2.jpg");
require("./images/rsgis_team_2019.png");

require("./images/mainheader.jpg");
require("./images/film-bw.jpg");
require("./images/default-profile.jpg");
require("./images/1920x360.png")
require("./images/1600x300.png")
require("./images/1440x300.png")
require("./images/1336x256.png")
require("./images/1280x267.png")
require("./images/1024x256.png")
require("./images/Imagery1920x360.png")
require("./images/Imagery1600x300.png")
require("./images/Imagery1440x300.png")
require("./images/Imagery1336x256.png")
require("./images/Imagery1280x267.png")
require("./images/Imagery1024x256.png")
require("./images/UAS1920x360.png")
require("./images/UAS1600x300.png")
require("./images/UAS1440x300.png")
require("./images/UAS1336x256.png")
require("./images/UAS1280x267.png")
require("./images/UAS1024x256.png")

require("./images/AppDev1920x360.png")
require("./images/AppDev1600x300.png")
require("./images/AppDev1440x300.png")
require("./images/AppDev1336x256.png")
require("./images/AppDev1280x267.png")
require("./images/AppDev1024x256.png")

require("./images/Aeromapper_hand_launch.png")
require("./images/Aeromapper_hand_launch_1920x520.png")
require("./images/Aeromapper_hand_launch_1920x750.png")
require("./images/Aeromapper_hand_launch_1280x600.png")
require("./images/Aeromapper_hand_launch_420x550.png")
require("./images/water-overflow_1920x750.png")
require("./images/water-overflow_1280x600.png")
require("./images/water-overflow_420x550.png")
require("./images/air-monitoring-211124_1920.png")
require("./images/air-monitoring-211124_1920x520.png")
require("./images/Screen_Shot_2019-09-09.png")
require("./images/Screen_Shot_2019-09-09_1920x520.png")
require("./images/forest-272595_1920.png")
require("./images/forest-272595_1920x520.png")
require("./images/forest-272595_1920x750.png")
require("./images/forest-272595_1280x600.png")
require("./images/forest-272595_420x550.png")

require("./images/Bob_NatGeo.png")
require("./images/CoursePack_BeyondBasics.png")
require("./images/CoursePack_IntroArcGIS.png")
require("./images/CoursePack_UAS.png")
require("./images/RSGIS_BubbleGraph.png")

require("./images/rsgis_team_2019.png")

require("./images/Profile_Bob.png")
require("./images/Profile_Christian.png")
require("./images/Profile_Christina.png")
require("./images/Profile_Dan.png")
require("./images/Profile_Dylan.png")
require("./images/Profile_Erin.png")
require("./images/Profile_Joe.png")
require("./images/Profile_Nick.png")
require("./images/Profile_Sean.png")
require("./images/Profile_Tamsyn.png")

require("./images/ProjectExample_CityOfMonroeTreeInventory.png")
require("./images/ProjectExample_IngCounty.png")
require("./images/ProjectExample_TitanicMapping.png")


require("./images/AA1920x360.png")
require("./images/AA1600x300.png")
require("./images/AA1440x300.png")
require("./images/AA1336x256.png")
require("./images/AA1280x256.png")
require("./images/AA1024x256.png")

require("./images/Blackboard1920x360.png")
require("./images/Blackboard1600x300.png")
require("./images/Blackboard1440x300.png")
require("./images/Blackboard1336x256.png")
require("./images/Blackboard1280x256.png")
require("./images/Blackboard1024x256.png")

require("./images/Docs1920x360.png")
require("./images/Docs1600x300.png")
require("./images/Docs1440x300.png")
require("./images/Docs1336x256.png")
require("./images/Docs1280x256.png")
require("./images/Docs1024x256.png")

require("./images/GIS1920x360.png")
require("./images/GIS1600x300.png")
require("./images/GIS1440x300.png")
require("./images/GIS1336x256.png")
require("./images/GIS1280x256.png")
require("./images/GIS1024x256.png")

require("./images/GPS1920x360.png")
require("./images/GPS1600x300.png")
require("./images/GPS1440x300.png")
require("./images/GPS1336x256.png")
require("./images/GPS1280x256.png")
require("./images/GPS1024x256.png")

require("./images/ILT1920x360.png")
require("./images/ILT1600x300.png")
require("./images/ILT1440x300.png")
require("./images/ILT1336x256.png")
require("./images/ILT1280x256.png")
require("./images/ILT1024x256.png")

require("./images/IS1920x360.png")
require("./images/IS1600x300.png")
require("./images/IS1440x300.png")
require("./images/IS1336x256.png")
require("./images/IS1280x256.png")
require("./images/IS1024x256.png")

require("./images/Online1920x360.png")
require("./images/Online1600x300.png")
require("./images/Online1440x300.png")
require("./images/Online1336x256.png")
require("./images/Online1280x256.png")
require("./images/Online1024x256.png")

require("./images/Research1920x360.png")
require("./images/Research1600x300.png")
require("./images/Research1440x300.png")
require("./images/Research1336x256.png")
require("./images/Research1280x256.png")
require("./images/Research1024x256.png")

require("./images/Vinifera1920x360.png")
require("./images/Vinifera1600x300.png")
require("./images/Vinifera1440x300.png")
require("./images/Vinifera1336x256.png")
require("./images/Vinifera1280x256.png")
require("./images/Vinifera1024x256.png")
require("./images/ViniferaWebApp.png")

require("./images/DennisHudson1.png")
require("./images/DennisHudson2.png")
require("./images/DennisHudson3.png")

require("./images/DavidLusch1.png")
require("./images/DavidLusch2.png")
require("./images/DavidLusch3.png")

require("./images/FAA.png")

require("./images/innovation1920.png")
require("./images/innovation420x550.png")

require("./images/ThematicResearchCoastal.png")
require("./images/ThematicResearchCoastal420x550.png")

require("./images/DS_TSP.png")

require("./images/ATSP_1920x360.png")
require("./images/ATSP_1600x300.png")
require("./images/ATSP_1440x300.png")
require("./images/ATSP_1336x256.png")
require("./images/ATSP_1280x256.png")
require("./images/ATSP_1024x256.png")

require("./images/coast1.png")
require("./images/ClimateAppScreenshot.png")

require("./images/designPageCorps3.png")
require("./images/designPageCosmetic2.png")
require("./images/designPageEnvir3.png")
require("./images/designPageSurgery2.png")

require("./images/designPageNextSite.png")
require("./images/designPageLaravelSite.png")
require("./images/designPageLumenSiteLogo.png")
require("./images/designPageReactSite2.png")

require("./images/designPageCMSSite.png")
require("./images/designPageWordSite5.png")

/********************
 *   PDFs  *
 ********************/
require("./images/PDFs/comparison.pdf");
require("./images/PDFs/geologic_history_huron_ws.pdf");
require("./images/PDFs/groundwater.pdf");
require("./images/PDFs/mi_coordinate_systems.pdf");
require("./images/PDFs/MUCC_GW-Surface_Water_Briefing.pdf");
require("./images/PDFs/Sci_Basis_for_GW_Protection_Oakland_Oct_2004s.pdf");
require("./images/PDFs/WT_Mapping_AWWA_Feb03.pdf");
require("./images/PDFs/ProvostVisit.TownHallAgenda10.28.2020.pdf");
require("./images/PDFs/Apples_Article.pdf");
require("./images/PDFs/MDOT_ForumFocusGroupConsentForm[4702].pdf");

require("./images/PDFs/Vinifera/Township_DD_Map_Allegan_Red.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Arenac_Red.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Bay_Red.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Berrien_Red.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Emmet_Red.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_GrandTraverse_Red.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Huron_Red.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Iosco_Red.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Leelanau_Red.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Manistee_Red.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Mason_Red.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Muskegon_Red.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Oceana_Red.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Ottawa_Red.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Sanilac_Red.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_StClair_Red.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_VanBuren_Red.pdf");

require("./images/PDFs/Vinifera/Township_DD_Map_Allegan_White.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Arenac_White.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Bay_White.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Berrien_White.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Emmet_White.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_GrandTraverse_White.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Huron_White.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Iosco_White.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Leelanau_White.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Manistee_White.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Mason_White.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Muskegon_White.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Oceana_White.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Ottawa_White.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_Sanilac_White.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_StClair_White.pdf");
require("./images/PDFs/Vinifera/Township_DD_Map_VanBuren_White.pdf");

/********************
 *      Videos      *
 ********************/

require("./videos/ArcmapSwipeToolTutorial.mp4")
require("./videos/GeoreferencingTraining.mp4")
require("./videos/IC-CREAM_CoastalGeotoolsPresentation.mp4")

/********************
 *   CSS Libraries  *
 ********************/
/******************
 *   CSS Custom   *
 ******************/
require("./less/main.less");
/********************
 *   JS Libraries   *
 ********************/


require("./js/scripts/app.js");


if (module.hot) {
  module.hot.accept();
}else{
  module.hot.decline()
}