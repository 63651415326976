const defaultState = {
    EventsArr: [],
    ActiveItem: null
}

export default (state = defaultState, action) => {
    if (action.type === "ADD_EVENTS_ITEM") {
        return {
            ...state,
            EventsArr: state.EventsArr.concat(action.obj)
        }
    }
    else if (action.type === "SET_ITEM_ACTIVE") {
        let id = action.id;
        let AItem = null;
        const NewArr = state.EventsArr.map((item)=>{
            item.Active = item.Id === id;
            if(item.Active){
                AItem = item;
            }
            return item;
        })
        return {
            EventsArr: NewArr,
            ActiveItem: AItem
        }
    }    
    else if(action.type === "CLEAR_EVENTS"){
        return {
            EventsArr: [],
            ActiveItem: null
        };
    }
    else {
        return state;
    }
};

