// React---------------------------------------
import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from "react-loadable";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { globalsSetBaseUrls } from "../actions/globalsActions";
// Other---------------------------------------
import { baseUrls } from "../../js/globals/objects";
// Store---------------------------------------
import configureStore from "../store/configureStore";
const store = configureStore();
store.dispatch(globalsSetBaseUrls(baseUrls))
// Loadable Components------------------------
const LoadableNavbarHOCLayout = Loadable({
    loader: () => import("../components/layout/Navbar/NavbarHOCLayout"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableMainHeader = Loadable({
    loader: () => import("../components/layout/Header/MainMSUHeader"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const Transparent = Loadable({
    loader: () => import("../components/layout/Transparent"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableMainTitle = Loadable({
    loader: () => import("../components/layout/Header/MainMSUTitle"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableFooter = Loadable({
    loader: () => import("../components/layout/Footer"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
// Loadable Pages-----------------------------
const LoadableIndex = Loadable({
    loader: () => import("../Pages/Index"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});

const LoadableImageTest = Loadable({
    loader: () => import("../Pages/Tests/responsive_images.js"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});

const LoadableServices = Loadable({
    loader: () => import("../Pages/Services/Services"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableGIS = Loadable({
    loader: () => import("../Pages/Services/GIS"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableImagery = Loadable({
    loader: () => import("../Pages/Services/Imagery"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableApplicationDevelopment = Loadable({
    loader: () => import("../Pages/Services/ApplicationDevelopment"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableUAS = Loadable({
    loader: () => import("../Pages/Services/UAS"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableGPS = Loadable({
    loader: () => import("../Pages/Services/GPS"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableCartographyGraphicDesign = Loadable({
    loader: () => import("../Pages/Services/CartographyGraphicDesign"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableWebDesign = Loadable({
    loader: () => import("../Pages/Services/WebDesign"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
// const LoadableTraining = Loadable({
//     loader: () => import("../Pages/Services/Training"),
//     loading() {
//         return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
//     }
// });

const LoadableEducation = Loadable({
    loader: () => import("../Pages/Education/Education"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
// const LoadableOLDInstructorLedTraining = Loadable({
//     loader: () => import("../Pages/Education/OLD_InstructorLedTraining"),
//     loading() {
//         return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
//     }
// });
const LoadableInstructorLedTraining = Loadable({
    loader: () => import("../Pages/Education/InstructorLedTraining"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableOnlineTraining = Loadable({
    loader: () => import("../Pages/Education/OnlineTraining"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableClassroom = Loadable({
    loader: () => import("../Pages/Education/Classroom"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});const LoadableVideos = Loadable({
    loader: () => import("../Pages/Education/Videos"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
// const LoadableWorkshops = Loadable({
//     loader: () => import("../Pages/Education/Workshops"),
//     loading() {
//         return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
//     }
// });
const LoadableDocuments = Loadable({
    loader: () => import("../Pages/Education/Documents"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadablRegistration = Loadable({
    loader: () => import("../Pages/Education/Registration"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});

const LoadableAerial = Loadable({
    loader: () => import("../Pages/Aerial/Aerial"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableAerialAbout = Loadable({
    loader: () => import("../Pages/Aerial/AerialAbout"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadablePolicies = Loadable({
    loader: () => import("../Pages/Aerial/Policies"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableSamples = Loadable({
    loader: () => import("../Pages/Aerial/Samples"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableFilm = Loadable({
    loader: () => import("../Pages/Aerial/Film"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableScale = Loadable({
    loader: () => import("../Pages/Aerial/Scale"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableGuide = Loadable({
    loader: () => import("../Pages/Aerial/Guide"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});

const LoadableResearch = Loadable({
    loader: () => import("../Pages/Research/Research"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableGrants = Loadable({
    loader: () => import("../Pages/Research/Grants"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableAffiliates = Loadable({
    loader: () => import("../Pages/Research/Affiliates"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableAffiliateProfile = Loadable({
    loader: () => import("../Pages/Research/AffiliateProfile"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableArticles = Loadable({
    loader: () => import("../Pages/Research/Articles"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableAppleTopographicSitePlanning = Loadable({
    loader: () => import("../Pages/Research/AppleTopographicSitePlanning"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableVinifera = Loadable({
    loader: () => import("../Pages/Research/Vinifera"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableThermalInfrared = Loadable({
    loader: () => import("../Pages/Research/ThermalInfrared"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableIceCream = Loadable({
    loader: () => import("../Pages/Research/IceCream"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});

const LoadableAboutUs = Loadable({
    loader: () => import("../Pages/About/AboutUs"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableMission = Loadable({
    loader: () => import("../Pages/About/Misson"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableStaff = Loadable({
    loader: () => import("../Pages/About/Staff"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableStaffProfile = Loadable({
    loader: () => import("../Pages/About/StaffProfile"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableHistory = Loadable({
    loader: () => import("../Pages/About/History"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});
const LoadableHallOfFame = Loadable({
    loader: () => import("../Pages/About/HallOfFame"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});

const LoadablePageNotFound = Loadable({
    loader: () => import("../Pages/PageNotFound"),
    loading() {
        return <div><i className="fas fa-spinner fa-pulse"></i> Loading...</div>
    }
});


const routes = (
    <Router>
        <Provider store={store}>
            <div class="msu-grid">
                <LoadableMainHeader />
                <Transparent />
                <LoadableMainTitle />
                <LoadableNavbarHOCLayout />
                <Switch>
                    <Route path={`${baseUrls.route}`} component={() => <LoadableIndex />} exact={true} />

                    {/* <Route path={`${baseUrls.route}ResponsiveImageDisplay`} component={() => <LoadableImageTest />} exact={true} /> */}

                    <Route path={`${baseUrls.route}services`} component={() => <LoadableServices />} exact={true} />
                    <Route path={`${baseUrls.route}services/gis`} component={() => <LoadableGIS />} exact={true} />
                    <Route path={`${baseUrls.route}services/imagery`} component={() => <LoadableImagery />} exact={true} />
                    <Route path={`${baseUrls.route}services/applicationdevelopment`} component={() => <LoadableApplicationDevelopment />} exact={true} />
                    <Route path={`${baseUrls.route}services/uas`} component={() => <LoadableUAS />} exact={false} />                    
                    <Route path={`${baseUrls.route}services/gps`} component={() => <LoadableGPS />} exact={true} />
                    <Route path={`${baseUrls.route}services/cartographygraphicdesign`} component={() => <LoadableCartographyGraphicDesign />} exact={true} />
                    <Route path={`${baseUrls.route}services/webdesign`} component={() => <LoadableWebDesign />} exact={true} />
                    <Route path={`${baseUrls.route}services/training`} component={() => <LoadableTraining />} exact={true} />

                    <Route path={`${baseUrls.route}education`} component={() => <LoadableEducation />} exact={true} />
                    <Route path={`${baseUrls.route}education/instructorledtraining`} component={() => <LoadableInstructorLedTraining />} exact={true} /> 
                    <Route path={`${baseUrls.route}education/onlinetraining`} component={() => <LoadableOnlineTraining />} exact={true} />
                    <Route path={`${baseUrls.route}education/classroom`} component={() => <LoadableClassroom />} exact={true} />
                    <Route path={`${baseUrls.route}education/workshops`} component={() => <LoadableWorkshops />} exact={true} />
                    <Route path={`${baseUrls.route}education/documents`} component={() => <LoadableDocuments />} exact={true} />
                    {/* <Route path={`${baseUrls.route}education/registration`} component={() => <LoadablRegistration />} exact={true} /> */}
                    {/* <Route path={`${baseUrl.route}education/videos`} component={() => <LoadableVideos />} exact={true} /> */}

                    <Route path={`${baseUrls.route}aerial`} component={() => <LoadableAerial />} exact={true} />
                    <Route path={`${baseUrls.route}aerial/about`} component={() => <LoadableAerialAbout />} exact={true} />
                    <Route path={`${baseUrls.route}aerial/policies`} component={() => <LoadablePolicies />} exact={true} />
                    <Route path={`${baseUrls.route}aerial/samples`} component={() => <LoadableSamples />} exact={true} />
                    <Route path={`${baseUrls.route}aerial/film`} component={() => <LoadableFilm />} exact={true} />
                    <Route path={`${baseUrls.route}aerial/scale`} component={() => <LoadableScale />} exact={true} />
                    {/* <Route path={`${baseUrls.route}aerial/guide`} component={() => <LoadableGuide />} exact={true} /> */}

                    <Route path={`${baseUrls.route}research`} component={() => <LoadableResearch />} exact={true} />
                    <Route path={`${baseUrls.route}research/grants`} component={() => <LoadableGrants />} exact={true} />
                    <Route path={`${baseUrls.route}research/affiliates`} component={() => <LoadableAffiliates />} exact={true} />
                    <Route path={`${baseUrls.route}research/affiliates/:UserName`} component={() => <LoadableAffiliateProfile />} exact={true} />
                    <Route path={`${baseUrls.route}research/articles`} component={() => <LoadableArticles />} exact={true} />
                    <Route path={`${baseUrls.route}research/appletopographic`} component={() => <LoadableAppleTopographicSitePlanning />} exact={true} />
                    <Route path={`${baseUrls.route}research/vinifera`} component={() => <LoadableVinifera />} exact={true} />
                    <Route path={`${baseUrls.route}research/thermalinfrared`} component={() => <LoadableThermalInfrared />} exact={true} />
                    <Route path={`${baseUrls.route}research/iccream`} component={() => <LoadableIceCream />} exact={true} />
                    
                    <Route path={`${baseUrls.route}about`} component={() => <LoadableAboutUs />} exact={true} />
                    <Route path={`${baseUrls.route}about/mission`} component={() => <LoadableMission />} exact={true} />
                    <Route path={`${baseUrls.route}about/staff`} component={() => <LoadableStaff />} exact={true} />
                    <Route path={`${baseUrls.route}about/staff/:UserName`} component={() => <LoadableStaffProfile />} exact={true} />
                    <Route path={`${baseUrls.route}about/history`} component={() => <LoadableHistory />} exact={true} />
                    <Route path={`${baseUrls.route}about/halloffame`} component={() => <LoadableHallOfFame />} exact={true} />
                    
                    <Route path="*" component={() => <LoadablePageNotFound />} />
                </Switch> 
            </div>
        </Provider>
    </Router>
);

var appRoot = document.getElementById("root");
ReactDOM.render(routes, appRoot);
var nojs = document.getElementById("nojs");
document.body.removeChild(nojs);