import { createStore, combineReducers } from 'redux';

import globalsReducer from "../reducers/globalsReducer";
import layoutReducer from "../reducers/layoutReducer";
import navbarReducer from "../reducers/navbarReducer";

import featuredNewsReducer from "../reducers/featuredNewsReducer";
import featuredEventsReducer from "../reducers/featuredEventsReducer";

import workshopRegisterReducer from "../reducers/workshopRegisterReducer";


const reducer = combineReducers({
    globals: globalsReducer,
    layout: layoutReducer,
    navbar: navbarReducer,

    news: featuredNewsReducer,
    events: featuredEventsReducer,

    workshopRegister: workshopRegisterReducer,
});

export default () => {
    const store = createStore(
        reducer, /* preloadedState, */
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
    return store;
}