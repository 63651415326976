const defaultState = {
    view: "transparent",
    showLeftPanel: true,
    tab: "service",
    showPowerMenu: false,
    powerMenuItems: null,
    megaMenuItems: null
}

export default (state = defaultState, action) => {
    if (action.type === "SET_LAYOUT_TYPE") {
        return {
            ...state,
            view: action.view
        }
    }
    else if (action.type === "TOGGLE_POWERMENU") {
        return {
            ...state,
            showPowerMenu: !state.showPowerMenu
        }
    }
    else if (action.type === "SET_POWER_MENU_ITEMS") {
        return {
            ...state,
            powerMenuItems: action.view
        }
    }
    else if (action.type === "SET_POWER_MENU") {
        return {
            ...state,
            showPowerMenu: action.show
        }
    }
    else if (action.type === "TOGGLE_MEGAMENU") {
        return{
            ...state,
            showMegaMenu: !state.showMegaMenu,
            megaMenuItems: null,
        }
    }
    else if (action.type === "SET_MEGA_MENU_ITEMS") {
        return {
            ...state,
            megaMenuItems: action.view
        }
    }
    else if (action.type === "SET_MEGA_MENU") {
        return {
            ...state,
            showMegaMenu: action.show
        }
    }
    else {
        return state;
    }
};

