const defaultState = {
    NewsArr: [],
    ActiveItem: null
}

export default (state = defaultState, action) => {
    if (action.type === "ADD_NEWS_ITEM") {
        return {
            ...state,
            NewsArr: state.NewsArr.concat(action.obj)
        }
    }
    else if (action.type === "SET_ITEM_ACTIVE") {
        let id = action.id;
        let AItem = null;
        const NewArr = state.NewsArr.map((item)=>{
            item.Active = item.Id === id;
            if(item.Active){
                AItem = item;
            }
            return item;
        })
        return {
            NewsArr: NewArr,
            ActiveItem: AItem
        }
    }    
    else if(action.type === "CLEAR_NEWS"){
        return {
            NewsArr: [],
            ActiveItem: null
        };
    }
    else {
        return state;
    }
};

