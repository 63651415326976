/* Local */
// export const baseUrl = {
//     route: "/",
//     path:"/public/",
//     public:"/public/",
// };
// export const baseUrls = {
//     route: "/",
//     path:"/public/",
//     public:"/public/",
// };
 
/* QA */
// export const baseUrl = {
//     route: "/home/", 
//     path:"/public/home/",
//     public:"/public/home/",
// };
// export const baseUrls = {
//     route: "/home/", 
//     path:"/public/home/",
//     public:"/public/home/",
// };
 
/* Prod */
export const baseUrl = {
    route: "/",
    path:"/dist/",
    public:"/dist/",
};
export const baseUrls = {
    route: "/",
    path:"/dist/",
    public:"/dist/",
};